import { ILanguages } from "Providers";
import { OnlineHeaderMessage } from "OnlineTypes";
import { BrowseModeMessage } from "./getOnlineBrowseOnlyInfo";

export const getTitleText = (pickupDate: string, pickupTime: string, isAsap: boolean, userLanguage: ILanguages) => {
    let titleTxt = "";
    if (isAsap) {
        titleTxt = userLanguage === "sv" ? "Snarast" : "Asap";
    } else {
        if (!!pickupTime) {
            titleTxt = `${pickupDate} ${pickupTime}`;
        }
    }
    return titleTxt;
};

export const getOnlineHeaderMessage = (
    browseModeMsg: BrowseModeMessage | undefined,
    customOpenMessage: string
): OnlineHeaderMessage => {
    if (!browseModeMsg && !!customOpenMessage) {
        return {
            titleKey: customOpenMessage,
            isCustom: true
        };
    } else if (!!browseModeMsg) {
        return {
            ...browseModeMsg,
            isCustom: false
        } as unknown as OnlineHeaderMessage;
    } else {
        return undefined;
    }
};
