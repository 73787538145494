import { CALCULATE_SERVICE_FEE } from "GraphQLQueries";
import { useMothershipQuery } from "Hooks";
import { getSubscriptionMeta } from "OnlineUtils";
import { useOnline, useSubscriptions } from "Providers";
import { useQoplaStore, useOrderWindowsStore } from "Stores";
import { OrderType, CalculateServiceFee } from "Types";
import { getWebOrderType } from "Utils";

/**
 * useServiceFeeQuery is a custom hook that calculates the service fee for an order, taking into account
 * various factors such as the selected shop, food preferences, user subscriptions, active orderWindow,
 * whether gift cards are being used, and any applicable discounts.
 *
 * @param {boolean} isUsingGiftCards - A flag indicating whether gift cards are being used for this order
 * @returns {Object} - The result of the CalculateServiceFee query, which contains the calculated service fee
 *                     and any additional information related to the fee calculation.
 */
export const useServiceFeeQuery = (isUsingGiftCards: boolean) => {
	// Extract required properties from the hooks
	const { selectedShop } = useQoplaStore();
	const { foodOptions } = useOnline();
	const { userSubscriptions } = useSubscriptions();
	const { getActiveOrderWindow } = useOrderWindowsStore();

	// Get the active orderWindow
	const orderWindow = getActiveOrderWindow();

	// Get the subscription meta for the active orderWindow
	const subscriptionMeta = getSubscriptionMeta(orderWindow, userSubscriptions);

	// Calculate the discount rate from the orderWindow, if a discount exists;
	// otherwise, set the discount rate to 0.
	const discountRate = orderWindow?.discount ? orderWindow.discount.rate / 100 : 0.0;

	// Define the query variables
	const queryVariables = {
		shopId: selectedShop?.id,
		orderType: OrderType.WEB,
		eatingOption: foodOptions.eatingPreference,
		webOrderType: getWebOrderType(foodOptions.eatingPreference),
		subscriptionMeta,
		isUsingGiftCards,
		discountRate
	};

	// Execute the query and return the result
	return useMothershipQuery<CalculateServiceFee>(CALCULATE_SERVICE_FEE, {
		variables: queryVariables
	});
};