import { MenuProductCategory, OnlineProductCategory } from "Types";
import { productCategoryActiveProducts } from ".";

const filterUpsellCategories = ({
    upsellCategory,
    upsellCategoryAtPayment
}: MenuProductCategory | OnlineProductCategory) => !(upsellCategory || upsellCategoryAtPayment);

const menuProductCategoryHasProducts = (menuProductCategory: MenuProductCategory, hideFromStockIds: Set<string>) => {
    const hasBundles = !!productCategoryActiveProducts(menuProductCategory.menuBundleProducts, hideFromStockIds).length;
    const hasMenus = !!productCategoryActiveProducts(menuProductCategory.menuProducts, hideFromStockIds).length;
    return hasBundles || hasMenus;
};

export { filterUpsellCategories, menuProductCategoryHasProducts };
