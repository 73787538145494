import { FaShoppingCart } from "@react-icons/all-files/fa/FaShoppingCart";
import { FaUtensils } from "@react-icons/all-files/fa/FaUtensils";
import { FaShippingFast } from "@react-icons/all-files/fa/FaShippingFast";
import { FaGift } from "@react-icons/all-files/fa/FaGift";

export const onlineEatingPreferencesIcons = {
    TAKE_AWAY: {
        icon: FaShoppingCart,
        text: "takeAway"
    },
    EAT_HERE: {
        icon: FaUtensils,
        text: "eatHere"
    },
    HOME_DELIVERY: {
        icon: FaShippingFast,
        text: "homeDelivery"
    },
    CATERING_TAKE_AWAY: {
        icon: FaGift,
        text: "cateringTakeAway"
    },
    CATERING_DELIVERY: {
        icon: FaGift,
        text: "cateringHomeDelivery"
    },
    "": {
        icon: undefined,
        text: "chooseHowToEat"
    }
};
