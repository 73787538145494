export const getOnlineModalStyles = (isOnlineExpress: boolean, type: ScreenOrientation["type"] | "") => {
    let modalStyles = {};
    let modalBodyStyles = {};

    if (isOnlineExpress) {
        modalStyles = {
            placement: "top",
            userSelect: "none",
            size: "md"
        };

        if (type === "portrait-primary" || type === "portrait-secondary") {
            modalStyles = {
                ...modalStyles,
                placement: "center"
            };
            modalBodyStyles = {
                maxHeight: "calc(60vh - 10em)"
            };
        } else {
            modalStyles = {
                ...modalStyles,
                placement: "center"
            };
            modalBodyStyles = {
                maxHeight: "calc(70vh - 10em)"
            };
        }
    } else {
        modalStyles = {
            size: "sm",
            userSelect: "auto",
            placement: "center"
        };
    }

    return { modalStyles, modalBodyStyles };
};
