import { CartProduct, CompanyLocale, OrderWindow, ServiceFee, UserGiftCard, VatRateAmount } from "Types";
import { calculateGiftCardValueUsed, parseTip } from "./onlineUtils";
import { TranslateFunc } from "Providers";
import {
    applyServiceFeeVatToVatRatesAndAmounts,
    calculateDeliveryFee,
    formatFinancialNumbers,
    getVatAmountArrayForProducts
} from "PriceUtils";
import { getCartTotalPrice } from "Utils";
import { VAT_RATES } from "Constants";

// Utility functions
type IsNonZero = (num: number) => boolean;
const isNonZero: IsNonZero = num => {
    return num !== 0;
};

type FormattedVatRate = {
    formattedAmount: string;
    formattedVatRate: string;
};

type FormatVatRatesAndAmounts = (
    vatRatesAndAmounts: VatRateAmount[],
    companyLocale: CompanyLocale,
    translate: TranslateFunc,
    label?: string
) => FormattedVatRate[];
const formatVatRatesAndAmounts: FormatVatRatesAndAmounts = (vatRatesAndAmounts, companyLocale, translate, label) => {
    return vatRatesAndAmounts.map(vatRateAndAmount => ({
        formattedAmount: formatFinancialNumbers(vatRateAndAmount.amount, companyLocale),
        formattedVatRate: `${translate("vat")} ${vatRateAndAmount.vatRate}% ${label ?? ""}`
    }));
};

// Calculation functions
type CalculateSubTotal = (cartProducts: CartProduct[]) => number;
const calculateSubTotal: CalculateSubTotal = cartProducts => {
    return getCartTotalPrice(cartProducts);
};

type CalculateSubTotalWithFees = (subTotal: number, addedDeliveryFee: number, serviceFeeAmount: number) => number;
const calculateSubTotalWithFees: CalculateSubTotalWithFees = (subTotal, addedDeliveryFee, serviceFeeAmount) => {
    return subTotal + addedDeliveryFee + serviceFeeAmount;
};

type CalculateTotalOrderPrice = (
    subTotal: number,
    serviceFeeAmount: number,
    hasGiftCard: boolean,
    giftCard: UserGiftCard | null,
    subTotalWithFees: number,
    hasTip: boolean,
    tip: number,
    addedDeliveryFee: number
) => number;
const calculateTotalOrderPrice: CalculateTotalOrderPrice = (
    subTotal,
    serviceFeeAmount,
    hasGiftCard,
    giftCard,
    subTotalWithFees,
    hasTip,
    tip,
    addedDeliveryFee
) => {
    let totalOrderPrice = subTotal + serviceFeeAmount + addedDeliveryFee;

    if (hasGiftCard) {
        const giftCardValueUsed = calculateGiftCardValueUsed(giftCard!.remainingAmount, subTotalWithFees);
        totalOrderPrice -= giftCardValueUsed;
    }

    if (hasTip) {
        totalOrderPrice += tip;
    }

    return totalOrderPrice;
};

type CalculateDeliveryVatRatesAndAmounts = (addedDeliveryFee: number) => VatRateAmount[];
const calculateDeliveryVatRatesAndAmounts: CalculateDeliveryVatRatesAndAmounts = addedDeliveryFee => {
    const DELIVERY_VAT_RATE = VAT_RATES.TWELVE;
    const addedDeliveryFeeNetPrice = addedDeliveryFee / ((DELIVERY_VAT_RATE + 100) / 100);
    const deliveryVatAmount = addedDeliveryFee - addedDeliveryFeeNetPrice;
    return [
        {
            vatRate: DELIVERY_VAT_RATE,
            amount: deliveryVatAmount
        }
    ];
};

export type OnlineCheckoutTotals = {
    formattedTotalOrderPrice: string;
    formattedServiceFee: string;
    hasServiceFee: boolean;
    formattedSubTotalVatRatesAndAmounts: FormattedVatRate[];
    formattedDeliveryFee: string;
    isDeliveryFree: boolean;
    formattedDeliveryVatRatesAndAmounts: FormattedVatRate[];
    formattedTip: string;
    hasTip: boolean;
    isZeroAmount?: boolean;
};

type CalculateOnlineCheckoutTotals = (
    orderWindow: OrderWindow | undefined,
    rawTip: number | string,
    giftCardDetails: UserGiftCard | null,
    serviceFee: ServiceFee | null,
    minAmountFreeDelivery: number,
    deliveryFee: number,
    companyLocale: CompanyLocale,
    translate: TranslateFunc
) => OnlineCheckoutTotals;
/**
 * Calculates and formats the online checkout totals for a given order, including various fees and taxes.
 *
 * @param {OrderWindow | undefined} orderWindow - The order window object containing the cart products.
 * @param {number | string} rawTip - The raw tip value provided by the user.
 * @param {UserGiftCard | null} giftCardDetails - The user's gift card details, if any.
 * @param {ServiceFee | null} serviceFee - The service fee object, if applicable.
 * @param {number} minAmountFreeDelivery - The minimum order amount to qualify for free delivery.
 * @param {number} deliveryFee - The base delivery fee.
 * @param {CompanyLocale} companyLocale - The company's locale information for formatting purposes.
 * @param {TranslateFunc} translate - The translation function for internationalization.
 * @returns {OnlineCheckoutTotals} An object containing the formatted online checkout totals.
 */
export const calculateOnlineCheckoutTotals: CalculateOnlineCheckoutTotals = (
    orderWindow,
    rawTip,
    giftCardDetails,
    serviceFee,
    minAmountFreeDelivery,
    deliveryFee,
    companyLocale,
    translate
) => {
    const cartProducts = orderWindow?.cartProducts ?? [];
    const tip = parseTip(rawTip);
    const hasTip = !!tip;
    const giftCard = giftCardDetails;
    const hasGiftCard = !!giftCard;
    const serviceFeeAmount = serviceFee?.amount ?? 0;

    // Calculate subtotals and fees
    const subTotal = calculateSubTotal(cartProducts);
    const addedDeliveryFee = calculateDeliveryFee(subTotal, minAmountFreeDelivery, deliveryFee);
    const subTotalWithFees = calculateSubTotalWithFees(subTotal, addedDeliveryFee, serviceFeeAmount);

    // Calculate and format delivery VAT rates and amounts
    const deliveryVatRatesAndAmounts = calculateDeliveryVatRatesAndAmounts(addedDeliveryFee);
    const formattedDeliveryVatRatesAndAmounts = formatVatRatesAndAmounts(
        deliveryVatRatesAndAmounts,
        companyLocale,
        translate,
        `(${translate("deliveryFee")})`
    );

    // Calculate and format subtotal VAT rates and amounts
    const cartVatRates = getVatAmountArrayForProducts(cartProducts);
    const subTotalVatRatesAndAmounts = applyServiceFeeVatToVatRatesAndAmounts(serviceFee, cartVatRates);
    const formattedSubTotalVatRatesAndAmounts = formatVatRatesAndAmounts(
        subTotalVatRatesAndAmounts,
        companyLocale,
        translate
    );

    // Calculate total order price
    const totalOrderPrice = calculateTotalOrderPrice(
        subTotal,
        serviceFeeAmount,
        hasGiftCard,
        giftCard,
        subTotalWithFees,
        hasTip,
        tip,
        addedDeliveryFee
    );

    return {
        formattedTotalOrderPrice: formatFinancialNumbers(totalOrderPrice, companyLocale),
        formattedServiceFee: formatFinancialNumbers(serviceFeeAmount, companyLocale),
        hasServiceFee: isNonZero(serviceFeeAmount),
        formattedSubTotalVatRatesAndAmounts,
        formattedDeliveryFee: formatFinancialNumbers(addedDeliveryFee, companyLocale),
        isDeliveryFree: addedDeliveryFee === 0,
        formattedDeliveryVatRatesAndAmounts,
        formattedTip: formatFinancialNumbers(parseTip(tip), companyLocale),
        hasTip,
        isZeroAmount: totalOrderPrice === 0
    };
};

type CalculateOnlineCheckoutTotal = (
    orderWindow: OrderWindow | undefined,
    rawTip: number | string,
    giftCardDetails: UserGiftCard | null,
    serviceFee: ServiceFee | null,
    minAmountFreeDelivery: number,
    deliveryFee: number
) => number;

export const calculateOnlineCheckoutTotal: CalculateOnlineCheckoutTotal = (
    orderWindow,
    rawTip,
    giftCardDetails,
    serviceFee,
    minAmountFreeDelivery,
    deliveryFee
) => {
    const cartProducts = orderWindow?.cartProducts ?? [];
    const tip = parseTip(rawTip);
    const hasTip = !!tip;
    const giftCard = giftCardDetails;
    const hasGiftCard = !!giftCard;
    const serviceFeeAmount = serviceFee?.amount ?? 0;

    // Calculate subtotals and fees
    const subTotal = calculateSubTotal(cartProducts);
    const addedDeliveryFee = calculateDeliveryFee(subTotal, minAmountFreeDelivery, deliveryFee);
    const subTotalWithFees = calculateSubTotalWithFees(subTotal, addedDeliveryFee, serviceFeeAmount);

    // Calculate total order price
    const totalOrderPrice = calculateTotalOrderPrice(
        subTotal,
        serviceFeeAmount,
        hasGiftCard,
        giftCard,
        subTotalWithFees,
        hasTip,
        tip,
        addedDeliveryFee
    );

    return totalOrderPrice;
};
