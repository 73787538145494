import moment from "moment";

import { ActiveHour, Menu, Settings, FoodOptions } from "Types";
import { getActiveMenus, getMenusByOnlineIds, getMenusFromEatingPreference } from "Utils";
import { isOnlineOpen, appendTodaysActiveHours } from "./";

export const getOnlineMenusAndActiveMenus = (
    getMenusByIds: Menu[],
    onlineActiveHours: ActiveHour[],
    settings: Settings,
    foodOptions: FoodOptions,
    backendDiff: number,
    isExpressOnline: boolean
): { activeMenus: Menu[]; menus: Menu[] } => {
    const _onlineMenuIds = isExpressOnline
        ? settings?.onlineSettings?.expressOnlineMenuIds
        : settings?.onlineSettings?.onlineMenuIds;
    const onlineMenus = getMenusByOnlineIds(getMenusByIds, _onlineMenuIds);

    const isClosed = !isOnlineOpen(onlineMenus, onlineActiveHours, backendDiff);

    const menusFromEatingPreference = getMenusFromEatingPreference(
        getActiveMenus(
            onlineMenus,
            settings.showOnKDSWithinMinutesBeforePickup,
            foodOptions.pickupOptions,
            isClosed,
            settings?.onlineSettings?.closeOnlineOrders,
            backendDiff
        ),
        foodOptions.eatingPreference,
        settings?.onlineSettings?.eatingOptions
    );

    const selectedDay = foodOptions.pickupOptions ? foodOptions.pickupOptions.date : moment().format("YYYY-MM-DD");

    let activeMenus = appendTodaysActiveHours(menusFromEatingPreference, selectedDay, backendDiff);

    activeMenus = activeMenus.length > 1 ? activeMenus.filter(menu => menu.todayActiveHours) : activeMenus;

    if (isClosed) {
        activeMenus = [...activeMenus].sort(({ todayActiveHours }) => (!todayActiveHours ? 1 : -1));
    }

    return {
        //@ts-ignore
        activeMenus,
        menus: onlineMenus
    };
};
