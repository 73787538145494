import { BrowseOptions } from "Types";

export const browseModeMessages = {
    isTemporarilyOffline: {
        titleKey: "isTemporarilyOfflineTitle",
        textKey: "isTemporarilyOfflineText"
    },
    isClosed: {
        titleKey: "isClosedTitle",
        textKey: "isClosedText"
    },
    closedOnlineOrders: {
        titleKey: "closedOnlineOrdersTitle",
        textKey: ""
    },
    isPaused: {
        titleKey: "isPausedTitle",
        textKey: "isPausedText"
    },
    rushHour: {
        titleKey: "rushHourTitle",
        textKey: "rushHourText"
    },
    hasNoActiveMenus: {
        titleKey: "hasNoActiveMenusTitle",
        textKey: "hasNoActiveMenusText"
    },
    globallyClosed: {
        titleKey: "globallyClosedMessage",
        textKey: "tryAgainLater"
    }
} as Record<BrowseOptions, { titleKey: string; textKey: string }>;

export type BrowseModeMessage = {
    titleKey: string;
    textKey: string;
};

export const getOnlineBrowseOnlyInfo = (
    browseOptions: Record<BrowseOptions, boolean>,
    customClosedMessage?: string
) => {
    let message: BrowseModeMessage | undefined;
    Object.entries(browseOptions).find(([key, value]) => {
        if (value) {
            if (key === BrowseOptions.IS_CLOSED || key === BrowseOptions.CLOSED_ONLINE_ORDERS) {
                message = {
                    titleKey: browseModeMessages[key].titleKey,
                    textKey: customClosedMessage ? customClosedMessage : browseModeMessages[key].textKey
                };
            } else {
                // @ts-ignore
                message = browseModeMessages[key];
            }
            return value;
        }
    });
    return message;
};
