import { MenuProduct, MenuBundleProduct } from "Types";

const productCategoryActiveProducts = (
    products: (MenuProduct | MenuBundleProduct)[],
    hideFromStockIds: Set<string>
) => {
    return products.filter(product => {
        const prod = "refBundleProduct" in product ? product.refBundleProduct : product.refProduct;
        return !hideFromStockIds.has(prod.id);
    });
};

export { productCategoryActiveProducts };
