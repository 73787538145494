import moment from "moment";

import { MIDNIGHT_HOURS } from "Constants";
import { Menu } from "Types";
import { getHoursAndMinutes, getShopsActiveDay } from "Utils";

export const appendTodaysActiveHours = (menus: Menu[], selectedDay: string, backendDiff: number) => {
    const selectedDayMoment = selectedDay ? moment(selectedDay) : moment().add(backendDiff, "ms");
    const _selectedDay = selectedDayMoment.format("dddd").toUpperCase();
    const yesterday = selectedDayMoment.subtract(1, "day").format("dddd").toUpperCase();

    return menus.map(menu => {
        let todayActiveHours = getShopsActiveDay(menu.activeHours, _selectedDay);
        if (!todayActiveHours) {
            const yesterdayActiveHour = getShopsActiveDay(menu.activeHours, yesterday);

            if (!!yesterdayActiveHour) {
                const [endHour] = getHoursAndMinutes(yesterdayActiveHour.stoppingHour);
                if (endHour < MIDNIGHT_HOURS) {
                    todayActiveHours = yesterdayActiveHour;
                }
            }
        }

        return {
            ...menu,
            todayActiveHours
        };
    });
};
