import React, { createRef } from "react";
import flatMap from "lodash/flatMap";
import omit from "lodash/omit";
import moment from "moment";
import FaFileInvoiceDollar from "@react-icons/all-files/fa/FaFileInvoiceDollar";
import FaCreditCard from "@react-icons/all-files/fa/FaCreditCard";

import { SwishLogoPng } from "Assets";
import {
    MenuBundleProduct,
    MenuProduct,
    MenuProductCategory,
    Modification,
    RefBundleProduct,
    RefProduct,
    Settings,
    SubscriptionProducts,
    Subscriptions,
    OnlineProducts,
    OnlineProductCategories,
    OnlineMenu,
    UserSubscriptions,
    CompanySubscriptionSettings,
    Menu,
    ActiveHour,
    LocalSubscriptionMeta,
    ProductsStock,
    OnlineProduct,
    OnlineEatingPreference,
    OnlineProductCategory,
    CartProduct,
    SelectedBundleProductItem,
    OrderWindow,
    SubscriptionMeta,
    DISCOUNT_TYPE,
    OnlinePaymentMethod,
    PartnerPromotionImageType,
    Table,
    CustomerType,
    FoodOptions,
    UserAccount,
    EatingPreference,
    OnlinePickupOptions
} from "Types";
import { TranslateFunc, ILanguages } from "Providers/languageProvider/LanguageProvider";
import { getOnlineMenusAndActiveMenus, transformMenusToOnlineMenus } from "../";
import {
    isEatingPreferenceCatering,
    appendDefaultCountryCodeIfMissing,
    convertDayOfWeekToEng,
    convertDayOfWeekToSwe,
    getDate,
    getHoursAndMinutes,
    toMoment
} from "Utils";
import { isBundleProduct, isVariablePriceType } from "ProductUtils";
import { ONLINE_SEARCH_CATEGORY_ID, ONLINE_SUBSCRIPTION_CATEGORY_ID, timeConstants, PaymentMethod } from "Constants";
import { roundNumber } from "NumberUtils";
import { OnlineCheckoutFormValues } from "../../../../pages/onlineCheckout/OnlineCheckout";
import { Maybe } from "../../../../../generated/graphql";

const getActivePage = (transitions: any[], pages: any[]) => {
    return transitions.map(({ item, props, key }) => {
        const Page = pages[item];
        return <Page key={key} style={props} />;
    });
};

const sortProducts = (products: (MenuBundleProduct | MenuProduct)[]) =>
    products.sort((a, b) => a.sortOrder - b.sortOrder);

const getSubscriptionProducts = (menuProductCategories: MenuProductCategory[], subscriptions: Subscriptions) => {
    const allSubProds: SubscriptionProducts = flatMap(subscriptions, subscription => subscription.subscriptionProducts);
    const allMenuProds = flatMap(menuProductCategories, menuProductCategory => [
        ...menuProductCategory.menuBundleProducts,
        ...menuProductCategory.menuProducts
    ]);

    return allSubProds.map(prod => {
        const foundProductInCategory = allMenuProds.find(product => {
            const prodTwo = "refBundleProduct" in product ? product.refBundleProduct : product.refProduct;

            return prodTwo.id === prod.refProductId;
        });

        return foundProductInCategory;
    });
};

const getModifications = (product: MenuBundleProduct | MenuProduct): Modification | undefined => {
    if ("refProduct" in product && !!product.modifications) {
        return product.modifications;
    } else if ("refProduct" in product && !!product.refProduct.modifications) {
        return product.refProduct.modifications;
    } else {
        return undefined;
    }
};

const getSubscriptionProductMeta = (
    subscriptionProductMeta: LocalSubscriptionMeta | undefined,
    allNonFreeSubscriptionProducts: LocalSubscriptionMeta[],
    productId: string
) => {
    if (subscriptionProductMeta) {
        return subscriptionProductMeta;
    } else {
        const foundSubProd = allNonFreeSubscriptionProducts.find(({ refProductId }) => refProductId === productId);
        return foundSubProd;
    }
};

const isProductStockTracked = (product: RefProduct | RefBundleProduct, productsStock: ProductsStock) => {
    if (isBundleProduct(product)) {
        /**
         * Grab all of the refProductIds from the bundle product and check if at least one is stock tracked
         *  If one is, then the whole bundle is stock tracked
         */
        const refBundleProduct = product as RefBundleProduct;
        const flatRefProductIdList = flatMap(
            refBundleProduct.bundleProductCategories,
            bundleProductCategory => bundleProductCategory.refProductIdList
        );

        return flatRefProductIdList.some(refProductId => productsStock.has(refProductId));
    } else {
        return productsStock.has(product.id);
    }
};

const getOnlineProducts = (
    products: (MenuProduct | MenuBundleProduct)[],
    outOfStockIds: Set<string>,
    hideFromStockIdsSet: Set<string>,
    productsIdsAndQuantity: { [key: string]: number },
    allNonFreeSubscriptionProducts: LocalSubscriptionMeta[],
    productsStock: ProductsStock
): OnlineProducts => {
    const allProducts = sortProducts(products);

    const _allProducts = allProducts.filter(product => {
        const prod = "refBundleProduct" in product ? product.refBundleProduct : product.refProduct;
        const shouldHideFromStock = hideFromStockIdsSet.has(prod.id);
        const hasVariablePrice = isVariablePriceType(prod.priceType);
        const excluded = shouldHideFromStock || hasVariablePrice;
        return !excluded;
    });

    return _allProducts.map(product => {
        const prod = "refBundleProduct" in product ? product.refBundleProduct : product.refProduct;
        const isOutOfStock = outOfStockIds && outOfStockIds.has(prod.id);
        const productQuantity = productsIdsAndQuantity[prod.id];

        const modifications = getModifications(product);
        const type = "refProduct" in product ? "REF_PRODUCT" : "REF_BUNDLE_PRODUCT";
        const isBundleProduct = type === "REF_BUNDLE_PRODUCT";

        const subscriptionProductMeta = getSubscriptionProductMeta(
            product.subscriptionProductMeta,
            allNonFreeSubscriptionProducts,
            prod.id
        );

        // If any of bundleProduct's items are stock tracked
        // the bundleProduct will be stock tracked
        const isStockTracked = isProductStockTracked(prod, productsStock);

        return {
            id: product.id,
            isOutOfStock,
            price: product.price,
            productQuantity,
            sortOrder: product.sortOrder,
            refBundleProduct: isBundleProduct ? (prod as RefBundleProduct) : undefined,
            refProduct: !isBundleProduct ? (prod as RefProduct) : undefined,
            menuBundleProduct: isBundleProduct ? (product as MenuBundleProduct) : undefined,
            menuProduct: !isBundleProduct ? (product as MenuProduct) : undefined,
            description: prod.description,
            allergens: prod.allergens,
            modifications,
            type,
            activeHours: product.activeHours,
            subscriptionProductMeta,
            menuBundleModifications: "refBundleProduct" in product ? product.menuBundleModifications : undefined,
            isStockTracked
        };
    });
};

const getMinOrderTotalAmount = (
    eatingPreference: OnlineEatingPreference,
    { homeDeliverySettings, cateringSettings }: Settings
) => {
    if (isEatingPreferenceCatering(eatingPreference)) {
        if (eatingPreference === EatingPreference.CATERING_DELIVERY) {
            return cateringSettings.minOrderDeliveryAmount;
        } else {
            return cateringSettings.minOrderPickupAmount;
        }
    } else if (eatingPreference === EatingPreference.HOME_DELIVERY) {
        return homeDeliverySettings.minOrderTotalAmount;
    } else {
        return 0;
    }
};

const getOnlineProductCategoriesActiveIndexes = (
    onlineProductCategories: OnlineProductCategories,
    initialIndexes: number[] = [0]
) => {
    return onlineProductCategories.reduce((acc, next, index) => {
        // @ts-ignore
        if (next.open) {
            acc.push(index);
        }
        return acc;
    }, initialIndexes);
};

const getOnlineProductCategoriesRefs = (menuProductCategories: MenuProductCategory[]) => {
    return menuProductCategories.reduce<{ [id: string]: React.RefObject<any> }>((acc, next) => {
        acc[next.id] = createRef();
        return acc;
    }, {});
};

const getOnlineProductCategories = (
    menuProductCategories: MenuProductCategory[],
    outOfStockIds: Set<string>,
    hideFromStockIdsSet: Set<string>,
    productsIdsAndQuantity: { [id: string]: number },
    allNonFreeSubscriptionProducts: LocalSubscriptionMeta[],
    productsStock: ProductsStock
): OnlineProductCategories => {
    const refs = getOnlineProductCategoriesRefs(menuProductCategories);

    return menuProductCategories.map(menuProductCategory => {
        const products = [...menuProductCategory.menuBundleProducts, ...menuProductCategory.menuProducts];
        const onlineProducts = getOnlineProducts(
            products,
            outOfStockIds,
            hideFromStockIdsSet,
            productsIdsAndQuantity,
            allNonFreeSubscriptionProducts,
            productsStock
        );
        const ref = refs[menuProductCategory.id];

        return {
            id: menuProductCategory.id,
            name: menuProductCategory.name,
            description: menuProductCategory.description,
            imageUrl: menuProductCategory.imageUrl,
            onlineProducts,
            ref,
            sortOrder: menuProductCategory.sortOrder,
            upsellCategoryAtPayment: menuProductCategory.upsellCategoryAtPayment,
            upsellCategory: menuProductCategory.upsellCategory,
            isSubscriptionCategory: menuProductCategory.isSubscriptionCategory,
            partnerPromotionImageType: menuProductCategory.partnerPromotionImageType
        };
    });
};

const getHiddenIdsSets = (shopOverride: any): Set<string> => {
    if (shopOverride) {
        return new Set([...shopOverride.hideStockProductIds, ...shopOverride.hideStockAddonNames]);
    }
    return new Set([]);
};

const getOnlineUpsellCategories = (onlineProductCategories: OnlineProductCategories) => {
    return onlineProductCategories.reduce<{
        onlineUpsellCategory: OnlineMenu["onlineUpsellCategory"];
        onlineUpsellCategoryAtPayment: OnlineMenu["onlineUpsellCategoryAtPayment"];
    }>(
        (acc, next) => {
            if (acc.onlineUpsellCategory && acc.onlineUpsellCategoryAtPayment) {
                return acc;
            }

            if (next.upsellCategory) {
                acc.onlineUpsellCategory = next;
            } else if (next.upsellCategoryAtPayment) {
                acc.onlineUpsellCategoryAtPayment = next;
            }

            return acc;
        },
        { onlineUpsellCategory: null, onlineUpsellCategoryAtPayment: null }
    );
};

type GetAndSetInitialMenusParams = {
    outOfStockIdsSet: Set<string>;
    hideFromStockIdsSet: Set<string>;
    productsIdsAndQuantity: { [key: string]: number };
    subscriptions: Subscriptions;
    userSubscriptions: UserSubscriptions;
    companySubscriptionSettings: CompanySubscriptionSettings | null;
    shopSettings: Settings;
    getMenusByIds: Menu[];
    onlineActiveHours: ActiveHour[];
    foodOptions: FoodOptions;
    backendDiff: number;
    translate: TranslateFunc;
    isExpressOnline?: boolean;
    productsStock: ProductsStock;
};

const getAndSetInitialMenus = ({
    outOfStockIdsSet,
    hideFromStockIdsSet,
    productsIdsAndQuantity,
    subscriptions,
    userSubscriptions,
    companySubscriptionSettings,
    shopSettings,
    getMenusByIds,
    onlineActiveHours,
    foodOptions,
    backendDiff,
    translate,
    isExpressOnline = false,
    productsStock
}: GetAndSetInitialMenusParams) => {
    if (getMenusByIds === undefined) {
        return null;
    }

    const { activeMenus, menus } = getOnlineMenusAndActiveMenus(
        getMenusByIds,
        onlineActiveHours,
        shopSettings,
        foodOptions,
        backendDiff,
        isExpressOnline
    );

    const onlineMenus = transformMenusToOnlineMenus(
        activeMenus as any,
        outOfStockIdsSet,
        hideFromStockIdsSet,
        productsIdsAndQuantity,
        subscriptions,
        userSubscriptions,
        companySubscriptionSettings,
        productsStock,
        translate
    );

    let allOnlineMenus: OnlineMenu[] = [];

    if (!onlineMenus.length) {
        allOnlineMenus = transformMenusToOnlineMenus(
            menus as any,
            outOfStockIdsSet,
            hideFromStockIdsSet,
            productsIdsAndQuantity,
            subscriptions,
            userSubscriptions,
            companySubscriptionSettings,
            productsStock,
            translate
        );
    }

    let activeMenu = null;
    if (!!onlineMenus.length) {
        const [_activeMenu] = onlineMenus.sort((a, b) => a.sortOrder - b.sortOrder);

        activeMenu = _activeMenu;
    }

    return {
        activeMenu,
        activeMenus: onlineMenus ?? [],
        menus,
        allOnlineMenus: allOnlineMenus
    };
};

const isOnlineProductIncrementDisabled = (
    onlineProduct: OnlineProduct,
    productsStock: ProductsStock,
    usedQuantity: number
) => {
    const isFreeSubscriptionProduct = onlineProduct.subscriptionProductMeta?.percentageDiscount === 100;
    if (isFreeSubscriptionProduct) {
        return true;
    }

    const refId = onlineProduct.refProduct?.id || (onlineProduct.refBundleProduct?.id as string);
    const isStockTracked = productsStock.has(refId);

    if (isStockTracked) {
        return productsStock.get(refId)!.remainingQuantity <= usedQuantity;
    }

    return false;
};

const isBetweenWorkingHours = (
    workingHours: ActiveHour | null,
    pickupOptions: { time: string; date: string } | null,
    isCatering: boolean | undefined,
    backendDiff: number
) => {
    if (!isCatering) {
        if (workingHours?.startingHour && workingHours?.stoppingHour) {
            let pickupDate = moment().add(backendDiff, "ms");

            if (pickupOptions?.time && pickupOptions?.time !== timeConstants.ASAP) {
                const pickupTime = getHoursAndMinutes(pickupOptions.time);
                pickupDate = moment(pickupOptions.date)
                    .hours(pickupTime[0])
                    .minutes(pickupTime[1])
                    .add(backendDiff, "ms");
            }

            const startTime = getHoursAndMinutes(workingHours.startingHour);
            const endTime = getHoursAndMinutes(workingHours.stoppingHour);

            return pickupDate.isBetween(
                moment(pickupDate).hours(startTime[0]).minutes(startTime[1]),
                moment(pickupDate).hours(endTime[0]).minutes(endTime[1]),
                "minutes",
                "[]"
            );
        } else return false;
    } else return true;
};

/**
 * Function to create the searched online menuProductCategory based on
 * the user's search query
 * @param {OnlineProductCategories} onlineProductCategories Available onlineProductCategories from menu
 * @param {string} productTextSearchValue Search query
 * @returns {OnlineProductCategory} which holds the found onlineProducts based on the search query
 */
const getOnlineProductCategoryFromSearchedProducts = (
    onlineProductCategories: OnlineProductCategories,
    productTextSearchValue: string
): OnlineProductCategory => {
    // We don't want to show subscriptions in the search
    const onlineProductCategoriesWithOnlyProducts = onlineProductCategories.filter(
        onlineProductCategory => onlineProductCategory.id !== ONLINE_SUBSCRIPTION_CATEGORY_ID
    );
    const flatOnlineProducts = flatMap(onlineProductCategoriesWithOnlyProducts, cat => cat.onlineProducts);

    const filteredOnlineProducts = flatOnlineProducts.filter(onlineProduct => {
        const onlineProductName = onlineProduct.refBundleProduct?.name || onlineProduct.refProduct?.name;

        return onlineProductName?.toLowerCase()?.includes(productTextSearchValue.toLowerCase());
    });

    return {
        id: ONLINE_SEARCH_CATEGORY_ID,
        name: ONLINE_SEARCH_CATEGORY_ID,
        upsellCategory: false,
        upsellCategoryAtPayment: false,
        ref: null,
        sortOrder: 0,
        onlineProducts: filteredOnlineProducts
    };
};

/**
 * [FUNCTION] used in search - for promoted product ids
 * Used to enable the promotion image to appear on the product tile
 * @param {OnlineProductCategories} onlineProductCategories
 * @returns {string[]} array of promoted products
 */
const getPromotedProductIds = (onlineProductCategories: OnlineProductCategories) => {
    /** Filter online categories by promotion type category + products */
    const promotedOnlineProduct = onlineProductCategories
        ?.filter(cat => cat.partnerPromotionImageType === PartnerPromotionImageType.CATEGORY_AND_PRODUCTS)
        .map(cat => cat.onlineProducts);

    /** Flap map promoted online products */
    const flatOnlineProducts = flatMap(promotedOnlineProduct);

    /** Return array of online product ids */
    return flatOnlineProducts.map(product => product.id);
};

const getOnlineProductQuantitiesInOnlineCategory = (
    cartProducts: CartProduct[],
    onlineProductCategory: OnlineProductCategory
) => {
    return (
        cartProducts
            ?.filter((cartProduct: CartProduct) => cartProduct.isFinished)
            .reduce((acc: number, cartProduct: CartProduct) => {
                // Is the cartProduct in this category?
                // If we don't have ONLINE_SEARCH_CATEGORY_ID checked, we wont re-render the categories on re-renders
                if (
                    onlineProductCategory.id == ONLINE_SEARCH_CATEGORY_ID ||
                    onlineProductCategory.id == cartProduct.orderProduct.menuCategoryId
                ) {
                    return acc + cartProduct.orderProduct.quantity;
                }

                // Are any selected bundle items in this category?
                const containsMatchingBundleItem = (cartProduct.orderProduct?.selectedBundleProductItems || []).find(
                    (selectedBundleProductItem: SelectedBundleProductItem) => {
                        // Need to compare the actual product ids, not the categoryIds
                        const hasMatchingBundleItem = onlineProductCategory.onlineProducts.find(
                            (onlineProduct: OnlineProduct) => {
                                const product = onlineProduct.refProduct
                                    ? (onlineProduct.refProduct as RefProduct)
                                    : (onlineProduct.refBundleProduct as RefBundleProduct);
                                return product.id == selectedBundleProductItem.refProductId;
                            }
                        );
                        return hasMatchingBundleItem;
                    }
                );

                if (containsMatchingBundleItem) {
                    return acc + cartProduct.orderProduct.quantity;
                }

                return acc;
            }, 0) || 0
    );
};

// NOTE in test suite
const isOnlineInvoicePaymentEnabled = (foodOptions: FoodOptions, doesAcceptInvoicePayment: boolean) => {
    const _isEatingPreferenceCatering = isEatingPreferenceCatering(foodOptions.eatingPreference);
    const isCustomerTypeOrganization = foodOptions?.deliveryInformation?.customerType === CustomerType.Organization;
    const canPayWithInvoice = doesAcceptInvoicePayment && _isEatingPreferenceCatering && isCustomerTypeOrganization;

    return canPayWithInvoice;
};

// NOTE in test suite
/**
 * Calculates the amount of gift card value used for an order based on the available amount and total order price.
 * @param {number} remainingAmount - The remaining amount of the gift card.
 * @param {number} totalOrderPrice - The total price of the order.
 * @returns {number} The gift card value used for the order.
 */
const calculateGiftCardValueUsed = (remainingAmount: number, totalOrderPrice: number) => {
    const availableAmount = remainingAmount;
    const excessAmount = Math.max(0, availableAmount - totalOrderPrice);
    const giftCardValueUsed = roundNumber(availableAmount - excessAmount);

    return giftCardValueUsed;
};

// NOTE in test suite
/**
 * Parses the raw tip value and returns its absolute (positive) number representation.
 * @param {(string|number|null)} rawTip - The raw tip value, which can be a string or number.
 * @returns {number} The absolute (positive) number representation of the raw tip value.
 */
const parseTip = (rawTip: number | string | null) => {
    const parsedTip = Number(rawTip);
    return isNaN(parsedTip) ? 0 : Math.abs(parsedTip);
};

const getSubscriptionMeta = (
    orderWindow: OrderWindow | undefined,
    userSubscriptions: UserSubscriptions
): SubscriptionMeta | null => {
    const subscriptionDiscount = orderWindow?.fixedDiscounts?.find(
        discount => discount.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT
    );
    const userSubscription = userSubscriptions.find(
        userSubscription => userSubscription.subscription.id === subscriptionDiscount?.id
    );

    if (userSubscription && subscriptionDiscount) {
        return {
            userSubscriptionId: userSubscription.id,
            name: userSubscription.subscription.name,
            subscriptionId: subscriptionDiscount.id
        };
    }

    return null;
};

// Returns the formatted date in the user's language.
const getFoundDate = (onlinePickupOptions: OnlinePickupOptions, userLanguage: ILanguages) => {
    const nowFormatted = getDate("dddd");
    const selectedDateFormatted = toMoment(onlinePickupOptions.date).format("dddd");

    if (nowFormatted === selectedDateFormatted) {
        return userLanguage === "sv"
            ? convertDayOfWeekToSwe(selectedDateFormatted)
            : convertDayOfWeekToEng(selectedDateFormatted);
    }

    return onlinePickupOptions.date;
};

// Returns the formatted date string for catering take away.
const formatCateringTakeAwayDate = (foundDate: string, activeHour?: ActiveHour) => {
    return `${foundDate} ${activeHour?.startingHour} - ${activeHour?.stoppingHour}`;
};

// Returns the formatted date string for catering delivery.
const formatCateringDeliveryDate = (foundDate: string, timeInterval?: string) => {
    return `${foundDate} ${!!timeInterval ? `(${timeInterval})` : ""}`;
};

// Returns the default text for the formatted date string.
const getDefaultText = (onlinePickupOptions: OnlinePickupOptions, foundDate: string) => {
    return `${onlinePickupOptions.time}, ${foundDate}`;
};

/**
 * Formats the pickup date string based on the provided parameters. The function takes into account
 * the user's language, eating preference, pickup hours, active hours, and time interval to generate
 * an appropriately formatted date string. The resulting date string is tailored to the user's
 * preferences and the specific conditions of the pickup (e.g., catering take away, catering delivery,
 * earliest time, or as soon as possible).
 *
 * @param {OnlinePickupOptions} onlinePickupOptions - An object containing information about the pickup date, time, and whether it's the earliest pickup.
 * @param {ILanguages} userLanguage - The user's language (e.g., "en" or "sv").
 * @param {OnlineEatingPreference} eatingPreference - The user's eating preference (e.g., EatingPreference.CATERING_TAKE_AWAY or EatingPreference.CATERING_DELIVERY).
 * @param {boolean} noPickupHours - A boolean indicating whether there are no pickup hours.
 * @param {(ActiveHour|undefined)} activeHour - An object containing information about the active hour (starting and stopping hours).
 * @param {string} timeInterval - An optional time interval for the pickup (e.g., "09:00 - 10:00").
 * @returns {string} A formatted pickup date string based on the provided parameters.
 */
const formatOnlinePickupDate = (
    onlinePickupOptions: OnlinePickupOptions,
    userLanguage: ILanguages,
    eatingPreference: OnlineEatingPreference,
    noPickupHours: boolean,
    activeHour: ActiveHour | undefined,
    timeInterval?: string
) => {
    const pickupDateToLang = {
        sv: {
            selectATime: "Välj en tid",
            asap: "Snarast",
            earliest: "Tidigast",
            today: "Idag"
        },
        en: {
            selectATime: "Pick a time",
            asap: "Asap",
            earliest: "Earliest",
            today: "Today"
        }
    };

    // If there is no pickup date, return the "select a time" text in the user's language.
    if (!onlinePickupOptions.date) {
        return pickupDateToLang[userLanguage].selectATime;
    }

    // Get the formatted date in the user's language.
    const foundDate = getFoundDate(onlinePickupOptions, userLanguage) as string;

    // If there are no pickup hours, format the date string based on the eating preference.
    if (noPickupHours) {
        if (eatingPreference === EatingPreference.CATERING_TAKE_AWAY) {
            return formatCateringTakeAwayDate(foundDate, activeHour);
        } else if (eatingPreference === EatingPreference.CATERING_DELIVERY) {
            return formatCateringDeliveryDate(foundDate, timeInterval);
        }
    }

    // Get the default formatted date text.
    const defaultText = getDefaultText(onlinePickupOptions, foundDate);

    // If the pickup time is ASAP, return the "asap" text in the user's language.
    if (onlinePickupOptions.time === timeConstants.ASAP) {
        return pickupDateToLang[userLanguage].asap;
    }
    // If the pickup is the earliest, return the "earliest" text in the user's language.
    else if (onlinePickupOptions.isEarliest) {
        const earliestText = pickupDateToLang[userLanguage].earliest;
        const todayText = pickupDateToLang[userLanguage].today;
        const isToday = getDate("YYYY-MM-DD");
        return isToday ? `${earliestText} ${defaultText} (${todayText})` : `${earliestText} ${defaultText} `;
    }

    // Otherwise, return the default formatted date text.
    return defaultText;
};

/**
 * Filter checkout form values based on eating preferences.
 *
 * @param {OnlineCheckoutFormValues} formValues - The form values object
 * @param {OnlineEatingPreference} eatingPreference - The selected eating preference
 * @returns {Object} - The filtered form values object
 */
const filterCheckoutValues = (formValues: OnlineCheckoutFormValues, eatingPreference: OnlineEatingPreference) => {
    // Define base properties to be omitted from the form values
    const baseOmitProps = [
        "saveMe",
        "comment",
        "comment2",
        "phoneNumber2",
        "paymentInformation",
        "tip",
        "giftCard",
        "discount",
        "__typename"
    ];

    // Define values to be omitted based on eating preferences
    const eatingPreferenceOmitValues = {
        HOME_DELIVERY: ["addressLine", "postCode", "doorCode"],
        CATERING_TAKE_AWAY: ["addressLine", "doorCode", "invoiceData"],
        CATERING_DELIVERY: ["addressLine", "postCode", "doorCode", "invoiceData"]
    };

    // Determine the properties to be omitted based on the selected eating preference
    const isEatingPreferenceValid =
        eatingPreference === EatingPreference.HOME_DELIVERY ||
        eatingPreference === EatingPreference.CATERING_TAKE_AWAY ||
        eatingPreference === EatingPreference.CATERING_DELIVERY;

    const omitPropsForPreference = isEatingPreferenceValid ? eatingPreferenceOmitValues[eatingPreference] : [];

    // Combine base and preference-specific properties to omit
    const omitValues = [...baseOmitProps, ...omitPropsForPreference];

    // Return the filtered form values object
    return omit(formValues, omitValues);
};

type NameResult = {
    firstName: string;
    lastName: string;
};

/**
 * Process the name string based on the delivery status and returns an object containing firstName and lastName.
 *
 * @param {string} name - The input name string containing first name and optionally last name.
 * @param {boolean} isDelivery - A boolean flag indicating whether it's a delivery or not.
 * @returns {NameResult} - An object containing firstName and lastName properties.
 */
const processFormValsCookieName = (name: string, isDelivery: boolean): NameResult => {
    const [firstName, lastName = ""] = name.trim().split(" ");

    return isDelivery
        ? { firstName: firstName, lastName: lastName }
        : { firstName: lastName ? `${firstName} ${lastName}` : firstName, lastName: "" };
};

type GetOnlineCheckoutDefaultValuesArgs = {
    userAccount: Maybe<UserAccount>;
    formValsCookie: any;
    foodOptions: FoodOptions;
    frozenEmail: string;
    savedPaymentMethod: Maybe<OnlinePaymentMethod>;
    defaultTable: Table | null;
    isDisableTableService?: boolean;
};
/**
 * Get initial online checkout values based on userAccount, formValsCookie, foodOptions, and frozenEmail.
 *
 * @param {GetOnlineCheckoutDefaultValuesArgs} args - An object containing userAccount, formValsCookie, foodOptions, and frozenEmail.
 * @returns {object} - An object with updated values based on input parameters.
 */
const getOnlineCheckoutDefaultValues = ({
    userAccount,
    formValsCookie,
    foodOptions,
    frozenEmail,
    savedPaymentMethod,
    defaultTable,
    isDisableTableService
}: GetOnlineCheckoutDefaultValuesArgs) => {
    let defaultValues = {};

    // Determine eating preferences and delivery status
    const isHomeDelivery = foodOptions.eatingPreference === EatingPreference.HOME_DELIVERY;
    const isCateringDelivery = foodOptions.eatingPreference === EatingPreference.CATERING_DELIVERY;
    const isDelivery = isHomeDelivery || isCateringDelivery;

    // Check if userAccount, frozenEmail, and formValsCookie are present
    const isUserAccountPresent = !!userAccount;
    const isFrozenEmailPresent = !!frozenEmail;
    const isFormValsCookiePresent = !!formValsCookie;

    // postCode has to come from CustomerPickupModal and not from userAccount
    // Since that postCode has been validated against the shop's available delivery areas
    const postCode = isDelivery ? foodOptions.deliveryInformation?.postCode : "";

    // Process userAccount data if present
    if (isUserAccountPresent) {
        const { contactInformation } = userAccount;

        const phoneNumber = appendDefaultCountryCodeIfMissing(contactInformation?.phoneNumber ?? "");

        defaultValues = {
            ...defaultValues,
            name: `${contactInformation?.name ?? ""} ${contactInformation?.lastName ?? ""}`,
            phoneNumber: phoneNumber,
            swishNumber: phoneNumber,
            email: contactInformation.email,
            acceptTerms: true,
            saveMe: true,
            acceptsCampaigns: true,
            ...(isDelivery && {
                name: `${contactInformation?.name ?? ""}`,
                lastName: `${contactInformation?.lastName ?? ""}`,
                addressLine: `${contactInformation?.addressLine ?? ""}`,
                city: `${contactInformation?.city ?? ""}`
            })
        };
        // Process formValsCookie data if present and userAccount is not present
    } else if (isFormValsCookiePresent) {
        const phoneNumber = appendDefaultCountryCodeIfMissing(formValsCookie?.phoneNumber ?? "");

        // Destructure the name value based on the eating option
        const name = `${formValsCookie?.name ?? ""} ${formValsCookie?.lastName ?? ""}`;
        const { firstName, lastName } = processFormValsCookieName(name, isDelivery);

        defaultValues = {
            ...defaultValues,
            // Remove the space between firstName and lastName if only firstName is present
            name: `${firstName} ${lastName}`.trimEnd(),
            phoneNumber: phoneNumber,
            swishNumber: phoneNumber,
            email: formValsCookie.email,
            acceptTerms: true,
            saveMe: true,
            acceptsCampaigns: true,
            ...(isDelivery && {
                name: firstName,
                lastName: lastName
                // AddressLine and postCode are not stored in the cookie
            })
        };
    }

    // Return the final object with updated values
    return {
        acceptTerms: isFormValsCookiePresent ?? false,
        ...defaultValues,
        ...(isDelivery && {
            postCode
        }),
        ...(isFrozenEmailPresent && {
            email: frozenEmail
        }),
        ...(isDisableTableService && { disableTableService: false }),
        tip: 0,
        table: defaultTable,
        paymentMethod: savedPaymentMethod
    };
};

export type OnlineCheckoutPaymentOption = {
    text: string;
    value: PaymentMethod;
    logo: any;
};
// NOTE in test suite
const getOnlineCheckoutPaymentOptions = (
    foodOptions: FoodOptions,
    doesAcceptInvoicePayment: boolean,
    translate: TranslateFunc
): OnlineCheckoutPaymentOption[] => {
    const swish = { text: "swish", value: PaymentMethod.SWISH, logo: SwishLogoPng };
    const card = { text: translate("card"), value: PaymentMethod.CARD, logo: FaCreditCard };
    const invoice = { text: translate("invoice"), value: PaymentMethod.INVOICE, logo: FaFileInvoiceDollar };

    const _isEatingPreferenceCatering = isEatingPreferenceCatering(foodOptions.eatingPreference);
    const isCustomerTypeOrganization = foodOptions?.deliveryInformation?.customerType === CustomerType.Organization;
    const shouldAppendInvoice = doesAcceptInvoicePayment && _isEatingPreferenceCatering && isCustomerTypeOrganization;

    let onlineCheckoutPaymentOptions = [swish, card];

    if (shouldAppendInvoice) {
        onlineCheckoutPaymentOptions.push(invoice);
    }

    return onlineCheckoutPaymentOptions;
};

const isDeliveryOrder = (eatingPreference: EatingPreference | "") => {
    if (!!eatingPreference) {
        return [EatingPreference.HOME_DELIVERY, EatingPreference.CATERING_DELIVERY].includes(eatingPreference);
    }
    return false;
};

const calculateTipValue = (tipPercent: number, totalCartPrice: number) => {
    return Number(tipPercent) * (totalCartPrice! / 100);
};

const formatTipValueToPercent = (tipValue: number) => {
    const option = {
        style: "percent",
        maximumFractionDigits: 2
    };
    return new Intl.NumberFormat("sv-se", option).format(tipValue / 100);
};

export {
    getActivePage,
    getMinOrderTotalAmount,
    getOnlineProductCategoriesActiveIndexes,
    getSubscriptionProducts,
    getOnlineProductCategories,
    getHiddenIdsSets,
    getAndSetInitialMenus,
    getOnlineUpsellCategories,
    isOnlineProductIncrementDisabled,
    isBetweenWorkingHours,
    getOnlineProductCategoryFromSearchedProducts,
    getOnlineProductQuantitiesInOnlineCategory,
    parseTip,
    calculateGiftCardValueUsed,
    getSubscriptionMeta,
    formatOnlinePickupDate,
    filterCheckoutValues,
    getOnlineCheckoutDefaultValues,
    processFormValsCookieName,
    getOnlineCheckoutPaymentOptions,
    isOnlineInvoicePaymentEnabled,
    isDeliveryOrder,
    getPromotedProductIds,
    calculateTipValue,
    formatTipValueToPercent
};
