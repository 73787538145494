import { getDate, toMoment, convertDayOfWeekToSwe, convertDayOfWeekToEng } from "Utils";
import { timeConstants } from "Constants";
import { ILanguages, EatingPreference } from "Providers";
import { ActiveHour, OnlineEatingPreference } from "Types";

const pickupDateToLang = {
    sv: {
        selectATime: "Välj en tid",
        asap: "Snarast",
        earliest: "Tidigast",
        today: "Idag"
    },
    en: {
        selectATime: "Pick a time",
        asap: "Asap",
        earliest: "Earliest",
        today: "Today"
    }
};

export const formatPickupDate = (
    pickup: { time: string; date: string; isEarliest: boolean },
    userLanguage: ILanguages,
    eatingPreference: OnlineEatingPreference,
    noPickupHours: boolean,
    activeHour: ActiveHour | undefined,
    timeInterval?: string
): string => {
    if (!pickup.date) {
        return pickupDateToLang[userLanguage].selectATime;
    }

    const nowFormatted = getDate("dddd");
    const isToday = getDate("YYYY-MM-DD");
    const selectedDateFormatted = toMoment(pickup.date).format("dddd");

    let foundDate;
    if (nowFormatted === selectedDateFormatted) {
        if (userLanguage === "sv") {
            foundDate = convertDayOfWeekToSwe(selectedDateFormatted);
        } else {
            foundDate = convertDayOfWeekToEng(selectedDateFormatted);
        }
    } else {
        foundDate = pickup.date;
    }

    if (noPickupHours && eatingPreference === EatingPreference.CATERING_TAKE_AWAY) {
        return `${foundDate} ${activeHour?.startingHour} - ${activeHour?.stoppingHour}`;
    } else if (noPickupHours && eatingPreference === EatingPreference.CATERING_DELIVERY) {
        return `${foundDate} ${!!timeInterval ? `(${timeInterval})` : ""}`;
    }

    let defaultText = `${pickup.time}, ${foundDate}`;

    if (pickup.time === timeConstants.ASAP) {
        return pickupDateToLang[userLanguage].asap;
    } else if (pickup.isEarliest) {
        const earliestText = pickupDateToLang[userLanguage].earliest;
        const todayText = pickupDateToLang[userLanguage].today;
        return isToday ? `${earliestText} ${defaultText} (${todayText})` : `${earliestText} ${defaultText} `;
    } else {
        return defaultText;
    }
};
