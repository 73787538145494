import { useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "react-apollo";

import { GET_SERVER_EPOCH } from "GraphQLQueries";

export const useBackendTime = () => {
    const [state, setState] = useState({ diff: 0, loading: true });
    const { data: serverEpochTime } = useQuery(GET_SERVER_EPOCH, {
        fetchPolicy: "network-only"
    });
    useEffect(() => {
        if (serverEpochTime) {
            const backendTime = moment(serverEpochTime.getServerEpoch);
            const today = moment();
            const diff = backendTime.diff(today);
            setState({ diff: diff, loading: false });
        }
    }, [serverEpochTime]);
    return state;
};
