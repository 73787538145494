import { useEffect, useState } from "react";
import { flatMap } from "lodash";

import {
    GET_INGREDIENTS_GROUP,
    GET_SHOP_OVERRIDE,
    GET_COMPANY_REF_PRODUCTS,
    GET_MENU_BY_IDS,
    GET_FIXED_DISCOUNT_BY_COMPANY_AND_SHOP_ID,
    GET_GIFT_CARD_BY_COMPANY_AND_SHOP,
    GET_PARTNER_PROMOTIONS_BY_ID,
    GET_ALCOHOL_PRODUCT_IDS
} from "GraphQLQueries";
import { useImperativeQuery } from "Hooks";
import { formatQueryDataResponse } from "Utils";

type LoadShopDataArgs = {
    companyId: string;
    shopId: string;
    menuIds: string[];
    partnerPromotionId?: string;
};

type State = {
    data: any | null;
    loading: boolean;
    errors: any | null;
    variables: null | LoadShopDataArgs;
};

export const useOnlineShopQueries = (shop: any, express = false): any => {
    const [onlineQueries, setOnlineQueries] = useState<State>({
        data: null,
        loading: true,
        errors: null,
        variables: null
    });

    const loadIngredientsGroup = useImperativeQuery(GET_INGREDIENTS_GROUP);
    const loadShopOverride = useImperativeQuery(GET_SHOP_OVERRIDE);
    const loadGetMenus = useImperativeQuery(GET_MENU_BY_IDS);
    const loadCompanyRefProducts = useImperativeQuery(GET_COMPANY_REF_PRODUCTS);
    const loadFixedDiscounts = useImperativeQuery(GET_FIXED_DISCOUNT_BY_COMPANY_AND_SHOP_ID);
    const loadGiftCard = useImperativeQuery(GET_GIFT_CARD_BY_COMPANY_AND_SHOP);
    const loadPartnerPromotion = useImperativeQuery(GET_PARTNER_PROMOTIONS_BY_ID);
    const loadAlcoholProductIds = useImperativeQuery(GET_ALCOHOL_PRODUCT_IDS);

    const loadData = async (variables?: LoadShopDataArgs) => {
        const _variables = onlineQueries.variables || variables;
        if (!_variables) return;
        const { companyId, shopId, menuIds, partnerPromotionId } = _variables;
        try {
            const res = await Promise.all([
                loadIngredientsGroup({ companyId }),
                loadShopOverride({ shopId }),
                loadGetMenus({ menuIds }),
                loadCompanyRefProducts({ companyId }),
                loadFixedDiscounts({ companyId, shopId }),
                loadGiftCard({ companyId, shopId }),
                loadPartnerPromotion({ partnerPromotionId }),
                loadAlcoholProductIds({ companyId })
            ]);
            const flatData = flatMap(res, e => e.data);

            setOnlineQueries({
                errors: null,
                loading: false,
                data: formatQueryDataResponse(flatData),
                variables: _variables
            });
        } catch (error) {
            setOnlineQueries({
                data: null,
                loading: false,
                errors: error,
                variables: _variables
            });
        }
    };

    useEffect(() => {
        if (!shop) return;

        const {
            settings: { onlineSettings }
        } = shop;

        const variables = {
            companyId: shop.companyId,
            shopId: shop.id,
            menuIds: express ? onlineSettings.expressOnlineMenuIds : onlineSettings.onlineMenuIds,
            partnerPromotionId: shop?.settings?.partnerPromotionId ?? null
        };
        loadData(variables);
    }, [shop]);

    return [onlineQueries, loadData];
};
