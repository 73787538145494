export type ScaleInterfaceCommand<T> = {
    // Command to actual send to the device
    command: string;
    parseResponse: (response: string) => T;
};

export type ScaleInterface = {
    // Not used, but maybe useful for logging
    name: string;
    // This is used to open request the serial port connection
    usbVendorId: number;

    // These settings are used when opening the serial port connection
    serialSettings: {
        baudRate: 2400 | 4800 | 9600;
        parity: "none" | "even" | "odd";
        stopBits: 1 | 1.5 | 2;
        dataBits: 9 | 8 | 7;
        terminalByte: number;
    };
    commands: {
        READ_WEIGHT: ScaleInterfaceCommand<ScaleWeightResponse>;
        DATE_TIME: ScaleInterfaceCommand<ScaleDateTimeResponse>;
        ZERO_SCALE: ScaleInterfaceCommand<ScaleNoResponse>;
    };
    readWeight: Function;
    calibrateZero: Function;
};

export type ScaleResponse = ScaleErrorResponse | ScaleWeightResponse;

export type ScaleUnit = "kg" | "hg" | "g" | "t";

export type ScaleErrorResponse = {
    error?: string | undefined;
};

export type ScaleWeightResponse = {
    weight: number;
    unit: ScaleUnit;
    status?: string | undefined;
    isChanging: boolean;
} & ScaleErrorResponse;

export type ScaleDateTimeResponse = {
    date: Date;
} & ScaleErrorResponse;

export type ScaleNoResponse = {} & ScaleErrorResponse;

export class SerialScaleAPI {
    // Scale specific implementation
    static scaleInterface: ScaleInterface;

    // Transient values
    static port: any;
    static outputStream: WritableStream<String> | undefined;
    static outputWriter: WritableStreamDefaultWriter<String> | undefined;
    static inputStream: ReadableStream<Uint8Array>;
    static inputReader: ReadableStreamDefaultReader<String> | undefined;
    static isConnected: boolean = false;
    static isDialogueOpen: boolean = false;
    static isBusy: boolean = false;

    static async connect(scaleInterface: ScaleInterface) {
        SerialScaleAPI.scaleInterface = scaleInterface;
        await SerialScaleAPI.acquireConnection();
    }

    static async acquireConnection() {
        if (SerialScaleAPI.isConnected) {
            // Skip connection acquisition
            return;
        }

        if (SerialScaleAPI.isDialogueOpen) {
            // Already choosing port to connect to
            return;
        }

        try {
            SerialScaleAPI.isDialogueOpen = true;

            // Check for scale support
            if (!("serial" in window.navigator)) {
                console.warn("No serial support found... Abandoning scale support");
                throw "Serial Web API not supported...";
            }

            if (!SerialScaleAPI.isConnected) {
                // Filter available ports based on interface type
                const requestOptions = {
                    filters: [{ usbVendorId: SerialScaleAPI.scaleInterface.usbVendorId }]
                };

                // Check if we can try connecting directly to the only device available
                const availablePorts = await (window.navigator as any).serial.getPorts();
                if (availablePorts.length == 1) {
                    SerialScaleAPI.port = availablePorts[0];
                } else {
                    // Open port selection dialogue
                    SerialScaleAPI.port = await (window.navigator as any).serial.requestPort(requestOptions);
                }

                // Open the port
                await SerialScaleAPI.port.open(SerialScaleAPI.scaleInterface.serialSettings);

                SerialScaleAPI.isConnected = true;
            }
        } catch (error) {
            SerialScaleAPI.isConnected = false;
            console.warn(`Error occured connecting to ${SerialScaleAPI.scaleInterface.name}`);
        } finally {
            SerialScaleAPI.isDialogueOpen = false;
        }
    }

    static async write(command: string) {
        SerialScaleAPI.isBusy = true;
        try {
            if (!SerialScaleAPI.outputWriter) {
                const encoder = new TextEncoderStream();
                encoder.readable.pipeTo(SerialScaleAPI.port.writable);
                SerialScaleAPI.outputWriter = encoder.writable.getWriter();
            }

            const writer = SerialScaleAPI.outputWriter;
            const terminalByte = SerialScaleAPI.scaleInterface.serialSettings.terminalByte;
            if (writer) {
                const data = [command, String.fromCharCode(terminalByte)].join("");
                writer.write(data);
            }
        } catch (error) {
            console.warn(`Error writing to scale: ${JSON.stringify(error)}`);
            SerialScaleAPI.isConnected = false;
        } finally {
            SerialScaleAPI.isBusy = false;
        }
    }

    static async read() {
        SerialScaleAPI.isBusy = true;
        let output = "";
        try {
            if (!SerialScaleAPI.inputReader) {
                let decoder = new TextDecoderStream();
                SerialScaleAPI.port.readable.pipeTo(decoder.writable);
                SerialScaleAPI.inputReader = decoder.readable.getReader();
            }

            const reader = SerialScaleAPI.inputReader;
            const terminalByte = SerialScaleAPI.scaleInterface.serialSettings.terminalByte;
            const terminalCharacter = String.fromCharCode(terminalByte);

            if (reader === undefined) {
                console.warn("No serial input reader has been initialized...");
            }

            if (reader !== undefined) {
                let count = 0;
                let continueReading = true;
                while (continueReading) {
                    // It's possible for value.length > 1
                    const { value, done } = await reader.read();

                    if (!continueReading) {
                        console.warn(`Breaking after reading ${count} bytes`);
                        break;
                    }

                    const lastCharacter = value?.charAt(value.length - 1);
                    if (lastCharacter === terminalCharacter) {
                        // Found the end of transmission for this device
                        continueReading = false;
                    } else {
                        // Append the content of the read
                        output += value;
                    }
                    count++;
                }
            }
        } catch (error) {
            console.warn(`Error reading from scale: ${JSON.stringify(error)}`);
            SerialScaleAPI.isConnected = false;
        } finally {
            SerialScaleAPI.isBusy = false;
            return output;
        }
    }

    static isReady() {
        return SerialScaleAPI.isConnected && !SerialScaleAPI.isBusy;
    }

    static async readWeight(): Promise<ScaleWeightResponse> {
        if (!SerialScaleAPI.isConnected) {
            await SerialScaleAPI.connect(SerialScaleAPI.scaleInterface);
        }

        const command = SerialScaleAPI.scaleInterface.commands.READ_WEIGHT;
        await SerialScaleAPI.write(command.command);
        const response = await SerialScaleAPI.read();
        const commandResponse = await command.parseResponse(response);

        return commandResponse;
    }

    static async readDateTime() {
        await SerialScaleAPI.write(SerialScaleAPI.scaleInterface.commands.DATE_TIME.command);
        return await SerialScaleAPI.read();
    }

    static async zeroScale() {
        await SerialScaleAPI.write(SerialScaleAPI.scaleInterface.commands.ZERO_SCALE.command);
    }
}
