import {
    ScaleInterface,
    ScaleUnit,
    ScaleWeightResponse,
    ScaleDateTimeResponse,
    ScaleErrorResponse,
    ScaleNoResponse
} from "../ScaleInterfaceAPI";

export const Stathmos3070: ScaleInterface = {
    name: "Stathmos 3070",
    usbVendorId: 1659,
    serialSettings: {
        baudRate: 4800,
        parity: "none",
        stopBits: 1,
        dataBits: 8,
        terminalByte: 13
    },
    commands: {
        READ_WEIGHT: {
            command: "W",
            parseResponse: (response: string): ScaleWeightResponse => {
                /**
                 * The response should have the form:
                 * w VVVVVvv ee   SSS\n
                 *
                 * w - command type
                 * Vv - weight
                 * ee - unit
                 * SSS - status
                 * Length: 19 chars (including CR=13)
                 */
                const weightResponse: ScaleWeightResponse = {
                    weight: 0,
                    unit: "kg",
                    isChanging: true
                };

                if (!response) {
                    weightResponse.error = `No response present "${response}"`;
                    return weightResponse;
                }

                // Strip the terminal character, if present
                response = response.replace(String.fromCharCode(Stathmos3070.serialSettings.terminalByte), "");

                if (response.length != 18) {
                    weightResponse.error = `Malformed scale response "${response}"`;
                    return weightResponse;
                }

                /**
                 * Important to filter whitespace here
                 * Transforms:
                 * [ 'w', '', '00001100', 'g', '', '', '100' ]
                 *  to:
                 * [ 'w', '00001100', 'g', '100' ]
                 */
                const responseParts = response.split(" ").filter(part => part.length > 0);

                if (responseParts.length < 4) {
                    weightResponse.error = `Malformed scale response "${response}"`;
                    return weightResponse;
                }

                const echoCommandPart = responseParts[0];
                const weightPart = responseParts[1];
                const unitPart = responseParts[2];
                const statusPart = responseParts[3];

                // Check echo command
                if (echoCommandPart !== "w") {
                    weightResponse.error = `Incorrect command response, found "${echoCommandPart}"`;
                    return weightResponse;
                }

                // Read weight
                try {
                    weightResponse.weight = parseFloat(weightPart);
                } catch {
                    weightResponse.error = `Malformed weight part, found "${weightPart}"`;
                    return weightResponse;
                }

                // Read unit
                try {
                    weightResponse.unit = unitPart as ScaleUnit;
                } catch {
                    weightResponse.error = `Malformed unit part, found "${unitPart}"`;
                    return weightResponse;
                }

                // Status string
                weightResponse.status = statusPart;

                // Get if the weight is still changing
                try {
                    weightResponse.isChanging = statusPart.charAt(0) !== "0";
                } catch {
                    weightResponse.error = `Malformed status bit at index 0, found status "${statusPart}"`;
                    return weightResponse;
                }

                return weightResponse;
            }
        },
        DATE_TIME: {
            command: "TD",
            parseResponse: (response: string): ScaleDateTimeResponse => {
                /**
                 * The response should have the form:
                 * td-tt:tt~~~~dd-dd-dd~~\n
                 *
                 * td - command type
                 * tt:tt - time
                 * dd-dd-dd - date
                 * Length: 23 chars (including CR=13)
                 */
                const dateTimeResponse: ScaleDateTimeResponse = {
                    date: new Date(0)
                };

                if (!response) {
                    dateTimeResponse.error = `No response present "${response}"`;
                    return dateTimeResponse;
                }

                // Strip the terminal character, if present
                response = response.replace(String.fromCharCode(Stathmos3070.serialSettings.terminalByte), "");

                if (response.length != 22) {
                    dateTimeResponse.error = `Malformed scale response "${response}"`;
                    return dateTimeResponse;
                }

                const responseParts = response.split(" ").filter(part => part.length > 0);

                if (responseParts.length < 3) {
                    dateTimeResponse.error = `Malformed scale response "${response}"`;
                    return dateTimeResponse;
                }

                const echoCommandPart = responseParts[0];
                const timePart = responseParts[1];
                const datePart = responseParts[2];

                // Check echo command
                if (echoCommandPart !== "td") {
                    dateTimeResponse.error = `Incorrect command response, found "${echoCommandPart}"`;
                    return dateTimeResponse;
                }

                // Get if the weight is still changing
                try {
                    dateTimeResponse.date = new Date(`${datePart} ${timePart}`);
                } catch {
                    dateTimeResponse.error = `Malformed date/time information, found "${datePart} / ${timePart}"`;
                    return dateTimeResponse;
                }

                return dateTimeResponse;
            }
        },
        ZERO_SCALE: {
            command: "WZ",
            // No response from this command
            parseResponse: (): ScaleNoResponse => {
                return {};
            }
        }
    },
    readWeight: () => {},
    calibrateZero: () => {}
};
