import { ActiveHour, EatingOption, Menu } from "Types";
import { hasCateringMenu, isShopOpen } from "Utils";

export const isOnlineOpen = (menus: Menu[], onlineActiveHours: ActiveHour[], backendDiff: number) => {
    if (hasCateringMenu(menus)) {
        const cateringMenu = menus.find(menu => menu.eatingOptions.includes(EatingOption.CATERING));
        if (cateringMenu) {
            return isShopOpen(cateringMenu.activeHours, backendDiff);
        }
        return false;
    } else {
        return isShopOpen(onlineActiveHours, backendDiff);
    }
};
