import { BrowseOptions } from "Providers";

const shouldDimByMode = (statuses: string[]) => (browseMode: Record<BrowseOptions, boolean>) =>
    Object.entries(browseMode).some(([key, value]) => !statuses.includes(key) && value);

const dimExpress = shouldDimByMode(["rushHour", "isPaused"]);
const dimRegular = shouldDimByMode(["rushHour", "hasNoActiveMenus"]);
const dimRegularWithCatering = shouldDimByMode(["rushHour", "hasNoActiveMenus", "isClosed"]);

export { dimExpress, dimRegular, dimRegularWithCatering };
