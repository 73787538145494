import uuidv4 from "uuid/v4";

import { LOCAL_STORAGE_CONSTANTS } from "Constants";
import { useCookieConsent, useEffectOnce, useLocalStorage } from "Hooks";

type AnonymousUserIdReturn = {
    anonymousUserId: string | null;
    getAndSetAnonymousUserId: (setAnonymousUserId: boolean) => string | null;
    setAnonymousUserId: (setAnonymousUserId: boolean) => void;
};

type AnonymousUserIdReturnHook = () => AnonymousUserIdReturn;

export const useAnonymousUserId: AnonymousUserIdReturnHook = () => {
    const { hasFunctionalCookieConsent } = useCookieConsent();
    const [savedAnonUserId, setSavedAnonUserId, removeSavedAnonUserId] = useLocalStorage(
        LOCAL_STORAGE_CONSTANTS.ANONYMOUS_USER_ID,
        null
    );

    /** Used for customers that have already accepted functional cookies */
    useEffectOnce(() => {
        if (hasFunctionalCookieConsent && !savedAnonUserId) {
            setSavedAnonUserId(uuidv4());
        }
    });

    /** 
     * Used in Cookie consent modal when saving functional cookie consent
     */
    const setAnonymousUserId = (setAnonymousUserId: boolean) => {
        if (setAnonymousUserId && !savedAnonUserId) {
            setSavedAnonUserId(uuidv4());
        } else if (!setAnonymousUserId) {
            removeSavedAnonUserId();
        }
    };

    /** Use when submitting online checkout - save me is selected (which is part of functional cookie consent)
     * 1. If saved me value is true - check if it has saved user id if not return new
     * 2. If saved me value is false - should not have an anonymous id
     */
    const getAndSetAnonymousUserId = (setAnonymousUserId: boolean) => {
        if (setAnonymousUserId && !savedAnonUserId) {
            const newAnonUserId = uuidv4();
            setSavedAnonUserId(newAnonUserId);
            return newAnonUserId;
        } else if (!setAnonymousUserId) {
            removeSavedAnonUserId();
            return null;
        }
        return savedAnonUserId;
    };

    return {
        anonymousUserId: savedAnonUserId,
        getAndSetAnonymousUserId,
        setAnonymousUserId
    };
};
