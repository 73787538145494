import { hasCateringMenu } from "Utils";
import { Menu } from "Types";
import { BrowseOptions } from "Providers";
import { dimRegularWithCatering, dimRegular } from ".";

export const shouldDisableOnlineOrderCart = (menus: Menu[], browseMode: Record<BrowseOptions, boolean>) => {
    const _hasCateringMenu = hasCateringMenu(menus);

    if (_hasCateringMenu) {
        return dimRegularWithCatering(browseMode);
    } else {
        return dimRegular(browseMode);
    }
};
