import { useState } from "react";
import isEmpty from "lodash/isEmpty";

import { OnlineEatingPreference, RGBA, OnlineMenu } from "Types";
import { LOCAL_STORAGE_CONSTANTS } from "Constants";

export type ExpressOnlineSettingsType = {
    lightMode: boolean;
    background?: RGBA;
    color?: RGBA;
    eatingOptions: OnlineEatingPreference[];
    animation: boolean;
};

export const useExpressOnlineDisplaySettings = (activeMenus: OnlineMenu[] | null) => {
    const defaultSettings = {
        lightMode: false,
        eatingOptions: [],
        animation: false
    };

    const [expressOnlineDisplaySettings, setExpressOnlineDisplaySettings] = useState<ExpressOnlineSettingsType>(() => {
        const expressOnlineDisplaySettings: ExpressOnlineSettingsType = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_CONSTANTS.EXPRESS_ONLINE_DISPLAY_SETTINGS) || "{}"
        );

        if (isEmpty(expressOnlineDisplaySettings)) {
            return defaultSettings as any;
        } else {
            const eatingOptions = expressOnlineDisplaySettings?.eatingOptions
                ? expressOnlineDisplaySettings.eatingOptions
                : activeMenus && activeMenus[0]
                ? activeMenus[0].eatingOptions
                : [];

            const localStorageAnimationSetting = !!expressOnlineDisplaySettings?.animation
                ? expressOnlineDisplaySettings.animation
                : false;

            return {
                ...expressOnlineDisplaySettings,
                eatingOptions,
                animation: localStorageAnimationSetting
            };
        }
    });

    return { expressOnlineDisplaySettings, setExpressOnlineDisplaySettings };
};
