import { useEffect, useState } from "react";
import { isEqual } from "lodash";

import { GET_LAST_ORDER_BY_USER_ID_OR_ANONYMOUS_USER_ID } from "GraphQLQueries";
import { useMothershipLazyQuery } from "Hooks";
import { useAnonymousUserId } from "./useAnonymousUserId";
import { useAuthUser } from "Providers";
import { Shop } from "Types";
import { useOrderWindowsStore } from "Stores";

type LastOrderHookProps = (shop?: Shop) => void;

export const useOnlineLastOrders: LastOrderHookProps = shop => {
    const { anonymousUserId } = useAnonymousUserId();
    const { userAccount } = useAuthUser();
    const [prevUserAccountId, setPrevUserAccountId] = useState<string | undefined>(userAccount?.id);
    const [prevActiveMenuIds, setPrevActiveMenuIds] = useState<string[]>([]);

    const { setLastOrders, activeMenus } = useOrderWindowsStore();

    const [loadLastOrderProducts, { data: lastOrders }] = useMothershipLazyQuery(
        GET_LAST_ORDER_BY_USER_ID_OR_ANONYMOUS_USER_ID
    );

    const loadUsersLastOrders = (shopId: string, menuIds: string[]) => {
        loadLastOrderProducts({
            variables: {
                shopId,
                userAccountId: userAccount?.id,
                anonymousUserId,
                menuIds
            }
        });
    };

    useEffect(() => {
        if (lastOrders?.getLastOrderByShopAndAnonymousUserIdOrUserAccountId) {
            setLastOrders(lastOrders?.getLastOrderByShopAndAnonymousUserIdOrUserAccountId);
        }
    }, [lastOrders]);

    useEffect(() => {
        if (shop) {
            const activeMenuIds = activeMenus.map(menu => menu.id);
            // used to prevent firing request on every eating option change
            if (isEqual(activeMenuIds.sort(), prevActiveMenuIds.sort())) return;
            setPrevActiveMenuIds(activeMenuIds);
            loadUsersLastOrders(shop?.id, activeMenuIds);
        }
    }, [activeMenus]);

    useEffect(() => {
        if (shop) {
            // fire request only when user state has changed (eg. logged in)
            if (prevUserAccountId === userAccount?.id) return;
            const activeMenuIds = activeMenus.map(menu => menu.id);
            setPrevUserAccountId(userAccount?.id);
            loadUsersLastOrders(shop?.id, activeMenuIds);
        }
    }, [userAccount?.id]);
};
